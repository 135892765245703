import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const AlphaMedLandingPage = () => (
  <PhysicianLandingPage
    physician="Alpha Medical"
    institution="Alpha Medical"
    referralCode="ALPHAMED"
    physicianURL="https://alpha-med.net/"
  />
)

export default AlphaMedLandingPage
